import React from 'react';
import { TimelineMax } from 'gsap';

import s from './Fade.module.scss';

type Props = {
  isVisible?: boolean;
  delay?: number;
  children: React.ReactNode;
};

export const Fade = ({ isVisible = true, delay = 0, children }: Props) => {
  const [hasRun, setHasRun] = React.useState(false);
  const textEl = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!isVisible || hasRun) return;

    const t = new TimelineMax();

    if (!textEl.current) {
      return;
    }

    t.set(textEl.current, { opacity: 1 });

    t.fromTo(
      textEl.current,
      0.75,
      { opacity: 0, y: 25 },
      { opacity: 1, y: 0, ease: 'Power3.easeOut' },
      delay
    );

    setHasRun(true);

    return () => {};
  }, [isVisible]);

  return (
    <div ref={textEl} className={s.fade}>
      {children}
    </div>
  );
};
