import React from 'react';
import classnames from 'classnames';
import IconCaption from 'components/assets/icon-caption.svg';

import s from './Figure.module.scss';

type Props = {
  className?: string;
  caption?: React.ReactNode;
};

export const Caption = ({ caption, className }: Props) => {
  return (
    <div className={classnames(s.caption, className)}>
      <IconCaption />
      {typeof caption === 'string' ? <p>{caption}</p> : caption}
    </div>
  );
};
