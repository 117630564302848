import React from 'react';
import { Content } from 'components/content/Content';
import { ContentItem } from 'components/content/ContentItem';
import { Overlap } from 'components/overlap/Overlap';
import { OverlapItem } from 'components/overlap/OverlapItem';
import { RichText } from 'components/rich-text/RichText';

export const StaggeredBlocksSlice = ({ style, direction, items, isSlice }) => {
  if (style === 'Overlapping') {
    return (
      <Overlap isSlice={isSlice}>
        {items?.map((item, index) => (
          <OverlapItem
            key={index}
            image={item.image}
            caption={item.caption}
            tagline={item.tagline}
            title={item.title}
            text={RichText.render(item.text)}
            link={item.link}
            label={item.label}
            secondLink={item.secondLink}
            secondLabel={item.secondLabel}
            direction={direction}
            video={item.video}
            embed={item.embed}
          />
        ))}
      </Overlap>
    );
  }

  return (
    <Content isSlice={isSlice}>
      {items?.map((item, index) => (
        <ContentItem
          key={index}
          image={item.image}
          caption={item.caption}
          tagline={item.tagline}
          title={item.title}
          text={RichText.render(item.text)}
          link={item.link}
          label={item.label}
          secondLink={item.secondLink}
          secondLabel={item.secondLabel}
          direction={direction}
          video={item.video}
          embed={item.embed}
        />
      ))}
    </Content>
  );
};

export default StaggeredBlocksSlice;
