import React from 'react';
import classnames from 'classnames';
import { TweenLite } from 'gsap';

import { ILink } from 'utils/types';
import ChevronRight from 'components/assets/chevron-right.svg';
import IconPlus from 'components/assets/icon-plus.svg';
import IconMail from 'components/assets/icon-mail.svg';

import s from './IconButton.module.scss';

type Props = {
  color?: 'brand' | 'white' | 'dark' | 'mega';
  hover?: 'brand' | 'white' | 'dark';
  icon?: 'chevron' | 'plus' | 'close' | 'mail';
  link?: ILink;
  href?: string;
  children?: React.ReactNode;
  onClick?: () => void;
};

export const IconButton = ({
  color = 'brand',
  hover,
  icon = 'chevron',
  href,
  children,
  ...rest
}: Props) => {
  const contentRef = React.useRef<HTMLSpanElement | null>(null);
  const textRef = React.useRef<HTMLSpanElement | null>(null);
  const textWrapRef = React.useRef<HTMLSpanElement | null>(null);
  const hasText = typeof children === 'string' && children.trim().length > 0;
  const transitionSpeed = 0.2;

  const buttonEnter = () => {
    if (!hasText || window.matchMedia('(min-width: 45em)').matches === false) {
      return;
    }

    if (!textWrapRef.current) {
      return;
    }

    const textWidth = textWrapRef.current.offsetWidth;

    TweenLite.to(textRef.current, transitionSpeed, { width: textWidth });
    TweenLite.to(textWrapRef.current, transitionSpeed, { autoAlpha: 1 });
  };

  const buttonLeave = () => {
    if (!hasText || window.matchMedia('(min-width: 45em)').matches === false) {
      return;
    }

    TweenLite.to(textRef.current, transitionSpeed, { width: 0 });
    TweenLite.to(textWrapRef.current, transitionSpeed / 2, { autoAlpha: 0 });
  };

  React.useEffect(() => {
    if (!contentRef.current || !contentRef.current.parentNode) {
      return;
    }

    contentRef.current.addEventListener('mouseenter', buttonEnter);
    contentRef.current.parentNode.addEventListener('focus', buttonEnter);
    contentRef.current.addEventListener('mouseleave', buttonLeave);
    contentRef.current.parentNode.addEventListener('blur', buttonLeave);

    return () => {
      if (!contentRef.current || !contentRef.current.parentNode) {
        return;
      }

      contentRef.current.removeEventListener('mouseenter', buttonEnter);
      contentRef.current.parentNode.removeEventListener('focus', buttonEnter);
      contentRef.current.removeEventListener('mouseenter', buttonLeave);
      contentRef.current.parentNode.removeEventListener('blur', buttonLeave);
    };
  }, []);

  const buttonIcon = () => {
    switch (icon) {
      case 'chevron':
        return <ChevronRight />;

      case 'plus':
      case 'close':
        return <IconPlus />;

      case 'mail':
        return <IconMail />;

      default: {
        console.warn('Unhandled icon type:', `type: ${icon}`);
        return <></>;
      }
    }
  };

  const className = classnames(s.button, {
    [s.brand]: color === 'brand',
    [s.white]: color === 'white',
    [s.dark]: color === 'dark',
    [s.mega]: color === 'mega',
    [s.chevron]: icon === 'chevron',
    [s.plus]: icon === 'plus',
    [s.close]: icon === 'close',
    [s.mail]: icon === 'mail',
    [s.hoverBrand]: hover === 'brand',
    [s.hoverWhite]: hover === 'white',
    [s.hoverDark]: hover === 'dark',
    [s.hasText]: hasText,
  });

  const content = (
    <span className={s.button__content} ref={contentRef}>
      {hasText && (
        <span className={s.button__text} ref={textRef}>
          <span className={s.button__textWrap} ref={textWrapRef}>
            {children}
          </span>
        </span>
      )}
      <span className={s.button__icon}>{buttonIcon()}</span>
    </span>
  );

  if (href) {
    return (
      <a className={className} href={href} {...rest}>
        {content}
      </a>
    );
  }

  return (
    <button className={className} {...rest}>
      {content}
    </button>
  );
};
